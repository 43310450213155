import React from 'react';

import goshahat from '../images/goshahat.jpg';

import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';


const SecondPage = () => (
  <Layout>
    <Header />

    <div id="wrapper">
      <section id="main" className="wrapper">
        <div className="inner page-max">
          <span className="image fit">
            <img src={goshahat} alt="James Franzen" />
          </span>
          <h2 className="center-text">Bio</h2>
          <p>Hi, my name is James Franzen. Over the last 15 years, I have worked to develop my art studio, Radio Gosha.  This is a mix of animation, illustration, web and more to transmit ideas in my head out there, in the form of expression as my artwork.</p>
          <p>I believe expression shouldn’t be contained by a medium and that new ideas can always come forth through collaboration.  I like to work with music producers to help tell stories through music video production. You can check out most of my animations on my <a href="https://www.youtube.com/radiogosha">Youtube</a>.</p>
          <p>My artstyle is a minimal mix of flat-style and striking linework.  My specialty is around vector based software, but I also enjoy using traditional mediums, such as pen and ink or screen-printing. I like to collect my illustration work on my <a href="https://www.deviantart.com/goshadole">Deviantart</a>.</p>
          <p>It may take some time for me to produce work, in-between my full time work as an interaction designer.  I do this to avoid burnout.  I’d like to stay healthy and keep creating pieces that feel important to me and actually mean something, if I can.  Always positive about how my art with continue to morph and develop as well as the people I’ll meet through future collaborations.  If anything I'm able to produce helps make people’s lives better, or inspires you in some way, then I’ve done what I’ve set out to accomplish as Radio Gosha.</p>
          <hr/>
          <h3 className="center-text">Past Collaborations</h3>
          <br/>
          <p>Konami Digital Entertainment, Step Revolution, Andamiro, Capcom, Udon, Attack the Music, Neon Alley, Fun In Motion, Disko Warp, S3RL, Leeni, Alaguan, Smile.dk, Viverus Studios.</p>
          <hr/>
          <h3 className="center-text">Some books I'm in...</h3>
          <br/>
          <p>My Famicase Exhibition, <i>METEOR Club</i>, 2019<br/>
          Capcom Fighting Tribute (p.208), <i>Udon</i>, 2015<br/>
          Every Day Is Play (p.132), <i>Game Paused</i>, 2014<br/>
          Lord and Lady of the Underworld, <i>The Gorgonist</i>, 2014<br/>
          Monsters and Dames (p. 38), <i>Emerald City Comicon</i>, 2014<br/>
          Monsters and Dames (p. 31), <i>Emerald City Comicon</i>, 2013<br/>
          Left Coast Right Brain Activity Pack 2012, <i>Homebass</i>, 2012<br/>
          Mega Man Tribute (p.181), <i>Udon</i>, 2011</p>
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default SecondPage;
